import { createContext, useContext } from 'react';
import { getCookie } from 'cookies-next';

const NonCriticalBannerContext = createContext(true);

interface NonCriticalBannerProps {
  children: React.ReactNode;
}

const NonCriticalBannerCookie = (props: NonCriticalBannerProps) => {
  const { children } = props;

  const criticalBannerCookie: boolean =
    getCookie('safetyculture-non-critical-banner') === undefined ? false : true;

  return (
    <NonCriticalBannerContext.Provider
      value={criticalBannerCookie as boolean}
    >
      {children}
    </NonCriticalBannerContext.Provider>
  );
};

export default NonCriticalBannerCookie;

export const useNonCriticalBanner = () =>
  useContext(NonCriticalBannerContext);
