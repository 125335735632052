import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { init as amplitudeInitialise } from '@amplitude/analytics-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';

import SegmentTracker from './SegmentTracker';
import { useWebsite } from '@/utilities/contexts/Website';
import WEBSITE from '@/utilities/constants/Website';

const loadAnalytics = async () => {
  const [segment] = await Promise.all([
    AnalyticsBrowser.load({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string,
    }),
    amplitudeInitialise(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, {
      defaultTracking: false,
    }),
  ]);
  return {
    segment,
  };
};

const segmentContext = createContext<SegmentTracker | null>(null);

/**
 * Segment Provider Component
 */
const SegmentProvider = (props: React.PropsWithChildren) => {
  const { children } = props;

  const router = useRouter();

  const previousPath = useRef<string | null>(null);
  
  const [tracker, setTracker] = useState<SegmentTracker | null>(null);

  const [isInitialLoad, setIsInitialLoad] = useState(false);

  const website = useWebsite();

  const productFeature =  WEBSITE[website].productFeature;
  
  useEffect(() => {
    
    if (tracker === null) {

      loadAnalytics().then((data) => {
        const {
          segment: [_analytics, _context],
        } = data;
        setTracker(new SegmentTracker(_analytics, _context, productFeature));
      });
    }

    // Hook into client-side routing
    // @see https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics-pages-router/components/analytics.tsx
    const handleRouteChange = (url: string) => {

      let data: Record<string, (string | null)[]> | undefined = undefined;

      if (window?.mutiny?.experiences && window.mutiny.experiences.length > 0) {
        data = window.mutiny.experiences.reduce(
          (prev, curr) => {
            const _new = { ...prev };
            Object.entries(curr).forEach(([key, value]) => {
              if (!_new[key]) {
                _new[key] = [value];
              } else {
                _new[key] = [..._new[key], value];
              }
            });
            return _new;
          },
          {} as Record<string, (string | null)[]>,
        );
      }

      const navigationEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];

      // relpad
      if (navigationEntries[0]?.type === 'reload') {
        if(!previousPath.current) {
          previousPath.current = url;
        }
      }

      // direct access
      if (navigationEntries[0]?.type === 'navigate' && !isInitialLoad) {
        previousPath.current = '';
      }

      // no previous path > set to the current URL
      if(!previousPath.current) {
        previousPath.current = '';
      }

      tracker?.visitedPage(previousPath?.current ? previousPath.current : '', { mutiny: data });
      tracker?.bindTrackingEvents();

      previousPath.current = url;
    };

    if (tracker !== null) {
      handleRouteChange(router.asPath);
      router.events.on('routeChangeComplete', handleRouteChange);
    }

    if(!isInitialLoad) {
      setIsInitialLoad(true);
    }

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };

    
  }, [router.events, tracker]);

  return (
    <segmentContext.Provider value={tracker}>
      {children}
    </segmentContext.Provider>
  );
};

/**
 * Context *must* possibly be null as it's asynchronously loaded
 */
export const useSegment = () => useContext(segmentContext);

export default SegmentProvider;
