import { createContext, useContext } from 'react';
import { getCookie } from 'cookies-next';
import { useWebsite } from '@/utilities/contexts/Website';
import WEBSITE from '@/utilities/constants/Website';

const CriticalBannerContext = createContext(true);

interface CriticalBannerProps {
  children: React.ReactNode;
}

const CriticalBannerCookie = (props: CriticalBannerProps) => {
  const { children } = props;

  const website = useWebsite();

  const cookieName = WEBSITE[website].banners.cookie.critical;

  const criticalBannerCookie: boolean =
    getCookie(cookieName) === undefined ? false : true;

  return (
    <CriticalBannerContext.Provider
      value={criticalBannerCookie as boolean}
    >
      {children}
    </CriticalBannerContext.Provider>
  );
};

export default CriticalBannerCookie;

export const useCriticalBanner = () =>
  useContext(CriticalBannerContext);
